<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Address</h3>
    <AddressCurrent
        v-model="form.current_address" :form="form" />
  </div>
</template>

<script>
import { fieldsetHelpers} from "@/components/common/questionnaires/fieldsets/fieldsetHelpers";

import AddressCurrent from "@/components/common/questionnaires/question/questions/clientProfile/address/AddressCurrent";

export default {
  name: 'Address',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    AddressCurrent
  },
  data() {
    return {
      label: 'Address' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
