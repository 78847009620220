<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Gender & DOB</h3>
    <Gender
        v-model="form.profileSex"
    />
    <Dob
        v-model="form.profileDOB"
    />
  </div>
</template>

<script>
import {fieldsetHelpers} from "../../fieldsetHelpers";
import { statusOptions } from "../../../question/options/statusOptions";

import Dob from "../../../question/questions/clientProfile/status/Dob";
import Gender from "../../../question/questions/clientProfile/status/Gender";

export default {
  name: 'GenderDOB',
  mixins: [fieldsetHelpers, statusOptions],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    Gender,
    Dob
  },
  data() {
    return {
      label: 'Gender & Birth' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
