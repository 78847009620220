<template>
  <div
      v-if="!mode.manual && data && (data.id || noSave) && !mode.search"
      class="question-box with-arrow address-box mt-30"
  >
    <div class="d-flex pt-30 pr-30">
      <i class="text-40 i-Home-4 pl-12 pr-24"></i>
      <p class="text-14">
        <strong> {{ data.line_1 }},</strong><br/>
        <span v-if="data.line_2">{{ data.line_2 }},<br/></span>
        <span v-if="data.town">{{ data.town }},<br/></span>
        <span v-if="data.post_town">{{ data.post_town }},<br/></span>
        <span v-if="data.county">{{ data.county }},<br/></span>
        {{ data.postcode }}<br/>
      </p>
    </div>
    <div class="question-box-controls w-100">
      <b-button
          class="btn mb-12"
          size="lg"
          variant="secondary ripple"
          @click="$emit('edit')"
      >Edit
      </b-button>
      <b-button
          size="lg"
          class="btn ml-12 mb-12"
          variant="outline-primary ripple"
          @click="$emit('delete')"
      >Change
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddressCard',
  props: {
    data: {
      type: Object,
      required: false
    },
    noSave: {
      type: Boolean,
      required: false
    },
    mode: {
      type: Object,
      required: true
    }
  }
}
</script>
