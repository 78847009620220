<template>
  <b-card
      :class="{'selected': selected, 'select-mode': selectMode}"
      no-body
  >
    <b-card-header
        class="header-elements-inline"
        header-tag="header"
        role="tab"
    >
      <div
          :aria-expanded="show.toString()"
          :class="{'card-title': showTitle}"
          class="mb-0 collapsed d-flex justify-content-between align-items-center"
          @click="select"
      ><span v-dompurify-html="title">

      </span>

        <slot name="customTag">
          <div v-if="disabled" class="badge badge-danger" style="position: absolute;bottom: -9px;right: 5%">
            Disabled
          </div>
          <div v-else-if="selected" class="badge badge-success" style="position: absolute;bottom: -9px;right: 5%">
            Selected
          </div>
          <div v-else-if="object && !object.partner && object.clients && object.clients.length && !badgeDisabled"
               class="badge badge-primary"
               style="position: absolute;bottom: -9px;right: 5%">
            {{
              clientNameList({
                clients: object.clients,
                clientDetails: $store.getters.family.clients,
                firstName: true
              })
            }}
          </div>
        </slot>

        <div
            v-if="(showDelete && ((!selectMode) || (selectMode && show))) || (showDelete && selected)"
            class="remove-item"
            @click.stop="$emit('delete')"
        >

        </div>

        <slot name="customButton">

        </slot>
        <div
            v-if="showSave"
            class="save-item"
            @click.stop="show ? $emit('save') : show = !show"
        />
      </div>
    </b-card-header>

    <b-collapse
        v-model="show"
        :accordion="object && object.id ?  'object-accordion-' + type : null"
        role="tabpanel"
    >
      <b-card-body>

        <slot></slot>

        <div
            v-if="showConfirm"
            class="save-button"
            @click="confirm"
        >
          Confirm
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {clientHelpers} from "@/mixins/clientHelpers";

export default {
  name: 'ObjectCard',
  mixins: [clientHelpers],
  props: {
    value: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    showDelete: {
      type: Boolean,
      default: true
    },
    showSave: {
      type: Boolean,
      default: true
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showConfirm: {
      type: Boolean,
      default: true
    },
    selectMode: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      required: false
    },
    index: {
      type: Number,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disableClick: {
      type: Boolean,
      required: false
    },
    loading: {
      type: Boolean,
      required: true
    },
    badgeDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showData: false,
    }
  },
  computed: {
    object: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    show: {
      set(value) {
        this.showData = value
      },
      get() {
        // if object have id (i.e. the object will be stored in db table)
        if ('id' in this.object) {
          return this.object.id ? this.showData : true
        }
        // if object is stored in db json field with no id but has index prop
        else {
          return this.index !== null && this.index >= 0 ? this.showData : true
        }
      }
    },
  },
  methods: {
    confirm() {
      if (!this.loading) {
        this.$emit('save')
      }
    },
    select() {
      if (this.disableClick) return
      if (this.disabled) return console.log('selecting this person is disabled')
      if (this.selectMode) {
        if (!this.showData) {
          this.$emit('click')
        }
      } else {
        this.showData = !this.showData
      }
    }
  }
}
</script>
