export const personalDetails = {
    data() {
        return {
            titleOptions: [
                {
                    label: 'Mr',
                    value: 'Mr'
                },
                {
                    label: 'Mrs',
                    value: 'Mrs'
                },
                {
                    label: 'Miss',
                    value: 'Miss'
                },
                {
                    label: 'Ms',
                    value: 'Ms'
                },
                {
                    label: 'Dr',
                    value: 'Dr'
                }
            ],
            titleOptionsMaleOrFemaleOnly: [
                {
                    label: 'Mr',
                    value: 'Mr'
                },
                {
                    label: 'Mrs',
                    value: 'Mrs'
                },
                {
                    label: 'Miss',
                    value: 'Miss'
                },
                {
                    label: 'Ms',
                    value: 'Ms'
                }
            ],
            genderOptions: [
                {
                    label: 'Male',
                    value: 'male',
                    thumb: 'https://mylastwill.s3.amazonaws.com/static/img/button-gender-male.png'
                },
                {
                    label: 'Female',
                    value: 'female',
                    thumb: 'https://mylastwill.s3.amazonaws.com/static/img/button-gender-female.png'
                },
                // {
                //     label: 'Other',
                //     value: 'other'
                // }
            ],
            relationshipOptions: [
                {
                    label: 'Single',
                    value: 'single'
                },
                {
                    label: 'Partnered',
                    value: 'partnered'
                },
                {
                    label: 'Engaged',
                    value: 'engaged'
                },
                {
                    label: 'Married',
                    value: 'married'
                },
                {
                    label: 'Civil Partnership',
                    value: 'civil partner'
                },
                {
                    label: 'Divorced',
                    value: 'divorced'
                },
                {
                    label: 'Separated',
                    value: 'separated'
                },
                {
                    label: 'Widowed',
                    value: 'widowed'
                }
            ],
            relationshipHaveOptions: [
                {
                    label: 'Partnered',
                    value: 'partnered'
                },
                {
                    label: 'Engaged',
                    value: 'engaged'
                },
                {
                    label: 'Married',
                    value: 'married'
                },
                {
                    label: 'Civil Partner',
                    value: 'civil partner'
                }
            ],
            relationshipPreviousStatusOptions: [
                {
                    label: 'Divorced',
                    value: 'divorced'
                },
                {
                    label: 'Separated',
                    value: 'separated'
                },
                {
                    label: 'Widowed',
                    value: 'widowed'
                }
            ]
        }
    }
}
