<template>
 <div>
   <InputBasic
       v-if="!data || mode.search"
       v-model="query" :args="args"
       :no-save="true"
       :db="{}"
       @otherFunction="setManual"
   />
   <div
       v-if="results.length && !mode.manual"
       class="question-box with-arrow mt-40 pt-30 pb-30"
   >
     <div class="question-box-intro-two mb-30">
       <h3>Select Your Address</h3>
     </div>
     <vue-perfect-scrollbar>
       <ul class="address-suggestions">
         <li
             v-for="(result, index) in results"
             :key="index"
             class="address-suggestion"
             @mousedown="selectResult(result.udprn)"
         >
           {{ result.suggestion }}
         </li>
       </ul>
     </vue-perfect-scrollbar>
   </div>

 </div>
</template>

<script>
import { clone } from "@/mixins/clone";
import { debounce } from 'lodash';
import { textHelpers } from '@/mixins/textHelpers';
import { blankForms } from "../../../question/helpers/blankForms";
import InputBasic from "../../../question/inputs/InputBasic";

export default {
  name: 'AddressSearch',
  components: {InputBasic},
  mixins: [clone, textHelpers, blankForms],
  props: {
    modeData: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: false
    },
    formData: {
      type: Object,
      required: false
    },
    disableManualMode: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    }
  },
  watch: {
    query () {
      this.search()
    }
  },
  computed: {
    mode: {
      set(value) {
        this.$emit('updateMode', value)
      },
      get() {
        return this.modeData
      }
    },
    form: {
      set(value) {
        this.$emit('formUpdate', value)
      },
      get() {
        return this.formData
      }
    }
  },
  data () {
    return {
      query: null,
      results: [],
      args: {
        placeholder: 'e.g. AB1 1AB',
        label: this.label ? this.label : null,
        rows: 4,
        maxRows: 4,
        inputClass: 'field-33',
        otherFunction: this.disableManualMode? null : {
          text: 'Enter Manually'
        }
      }
    }
  },
  methods: {
    setManual() {
      if (!this.data) this.setForm()
      this.mode.manual = !this.mode.manual
    },
    setForm() {
      this.form = this.clone(this.addressBlankForm)
    },
    search: debounce(function () {
      if (!this.query) {
        this.results = [];
        return;
      }

      this.$idealPostcodesClient.autocomplete
          .list({
            query: {
              query: this.query
            },
            header: {
              Authorization: `IDEALPOSTCODES api_key="${this.$idealPostcodesApiKey}"`
            }
          })
          .then((response) => {
            this.results = response.body.result.hits;
          })
          .catch((error) => console.log(error));
    }, 100),
    selectResult (udprn) {
      if (!this.form && !this.data) this.setForm()

      this.$idealPostcodesClient.udprn
          .retrieve(udprn, {
            header: {
              Authorization: `IDEALPOSTCODES api_key="${this.$idealPostcodesApiKey}"`
            }
          })
          .then((response) => {
            // set search result to form for post or to data for patch
            let location = null
            if (this.form && !this.data) location = 'form'
            else if (this.data && !this.form) location = 'data'
            // set data
            this[location].line_1 = response.body.result.line_1;
            this[location].line_2 = response.body.result.line_2;
            this[location].post_town = this.capitalise(
                response.body.result.post_town
            );
            this[location].county = response.body.result.county;
            this[location].postcode = response.body.result.postcode;
            this[location].country = response.body.result.country;
            this[location].district = response.body.result.district
            // save data
            this.$nextTick(() => {
              this.$emit('save', location)
              this.results = []
              //this.saveObject(this[location], null, this.clearAll)
            })
          })
          .catch((error) => console.log(error));
    }
  }
}
</script>
