<template>
  <QuestionBase
      :question="question"
      :valid="!!(valueFirst && valueLast)"
      :errors="errors"
      :disabled-valid="false"
  >
    <div class="question-multipart-wrapper d-flex flex-wrap align-items-end">
      <div class="question question-multipart field-33">
        <InputBasic
            :id="db.first.saveField + db.first.objectId"
            :value="valueFirst"
            @input="$emit('inputFirst', $event)"
            ref="firstName"
            :args="firstInputOptions"
            :db="db.first"
            :skipped="skipped"
            :no-save="noSave"
            :errors-post="errorsPostFirst"
            @skipped="skipped=$event"
            @serverErrors="serverErrors.firstName=$event"
            @jsErrors="jsErrors=$event"
        />

      </div>
      <div class="question question-multipart field-33">
        <InputBasic
            :value="valueMiddle"
            @input="$emit('inputMiddle', $event)"
            ref="middleName"
            :args="middleInputOptions"
            :db="db.middle"
            :skipped="skipped"
            :no-save="noSave"
            :errors-post="errorsPostMiddle"
            @skipped="skipped=$event"
            @serverErrors="serverErrors.middleName=$event"
            @jsErrors="jsErrors=$event"
        />
      </div>
      <div class="question question-multipart field-33">
        <InputBasic
            :value="valueLast"
            @input="$emit('inputLast', $event)"
            ref="lastName"
            :args="lastInputOptions"
            :db="db.last"
            :skipped="skipped"
            :serverErrors="serverErrors"
            :no-save="noSave"
            :errors-post="errorsPostLast"
            @skipped="skipped=$event"
            @serverErrors="serverErrors.lastName=$event"
            @jsErrors="jsErrors=$event"
        />
      </div>
    </div>
  </QuestionBase>
</template>

<script>
import {skipHelpers} from "../helpers/skipHelpers";
import {questionLoadHelpers} from "../../helpers/questionLoadHelpers";

import QuestionBase from "../../QuestionBase";
import InputBasic from "../../inputs/InputBasic";

export default {
  name: 'FullName',
  components: {
    InputBasic,
    QuestionBase
  },
  mixins: [skipHelpers, questionLoadHelpers],
  props: {
    valueFirst: {
      type: String,
      required: false
    },
    valueMiddle: {
      type: String,
      required: false
    },
    valueLast: {
      type: String,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPostFirst: {
      type: Array,
      default: () => {
        return []
      }
    },
    errorsPostMiddle: {
      type: Array,
      default: () => {
        return []
      }
    },
    errorsPostLast: {
      type: Array,
      default: () => {
        return []
      }
    },
    objectId: {
      type: [Number, String],
      required: false
    },
  },
  computed: {
    errors() {
      return [...this.errorsPostFirst, ...this.errorsPostMiddle, ...this.errorsPostLast, ...this.serverErrors.lastName, ...this.serverErrors.middleName, ...this.serverErrors.firstName, ...this.jsErrors]
    }
  },
  data() {
    return {
      question: {
        title: 'What is their full name?',
        subTitle: 'Please add their name as it would appear on any identification. If you are unsure if they have a middle name it is not essential that this be completed.',
        tip: null
      },
      firstInputOptions: {
        placeholder: 'e.g. Joesph',
        label: 'First Name:',
        type: 'text',
      },
      middleInputOptions: {
        placeholder: 'e.g. Richard',
        label: 'Middle Name(s):',
        type: 'text',
      },
      lastInputOptions: {
        placeholder: 'e.g. Blogs',
        label: 'Last Name:',
        type: 'text'
      },
      db: {
        saveLocation: 'client_person',
        saveField: 'name_first',
        first: {
          saveLocation: 'client_person',
          saveField: 'name_first',
          objectId: this.objectId

        },
        middle: {
          saveLocation: 'client_person',
          saveField: 'name_middle',
          objectId: this.objectId

        },
        last: {
          saveLocation: 'client_person',
          saveField: 'name_last',
          objectId: this.objectId

        },
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: {
        firstName: [],
        lastName: [],
        middleName: []
      },
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>