import {httpQuestionnaire} from "@/services";
import {clone} from "@/mixins/clone";
import { scrollTo } from "@/mixins/scrollTo";

export const objectSaveHelpers = {
    name: 'objectSaveHelpers',
    mixins: [clone, scrollTo],
    data () {
        return {
            loading: false
        }
    },
    methods: {
        saveObject(object, ref, callbackFunction=null, formObject=null, objectId=null) {
            console.log('save object main')
            if (object && object.id) this.patch(object, ref, formObject, objectId)
            else if (object && !object.id) this.post(object, callbackFunction, ref, formObject, objectId)
            else if (!object && ref) this.closeCard(ref)
            else if (callbackFunction) callbackFunction()
        },
        get() {
            httpQuestionnaire.get(this.db.loadLocation).then(
                response => {
                    this.data = response.data
                    this.$nextTick(() => {
                        if (this.db.callbackFunction) this.db.callbackFunction()
                        this.$emit('get')
                    })
                }
            ).catch(
                error => {
                    console.log(error)
                }
            )
        },
        // eslint-disable-next-line no-unused-vars
        post(object, callbackFunction=null, ref=null, formObject=null, objectId=null) {
            console.log('post ob save', object, callbackFunction, ref, formObject, objectId)
            this.clearErrors(formObject)
            // let data = {...object, access_code: this.$store.getters.accessCode}
            if (this.noSave) {
                // push or put object to array if not refreshing from server
                this.data = object
                if (formObject) this.forms[formObject] = null
                else this.form = null
                if (this.jsErrors) this.jsErrors = []
                if (callbackFunction) callbackFunction()
            } else {
                this.loading = true
                console.log('save this', object)
                httpQuestionnaire.post(this.db.saveLocation, object).then(
                    (response) => {
                        this.loading = false
                        // this.get()
                        // send refresh emit rather than get directly to ensure all people in fieldset are in sync
                        if (this.db.syncMultipleObjectOnSave) this.$emit('refresh')
                        else if (this.db.fetchObjectsOnSave) {
                            // fetch objects and push to form
                            this.get()
                        } else {
                            // push or put object to array if not refreshing from server
                            if (Array.isArray(this.data)) {
                                this.data.push(response.data)
                            } else {
                                this.data = response.data
                            }
                        }
                        if (formObject) this.forms[formObject] = null
                        else this.form = null

                        if (this.jsErrors) this.jsErrors = []
                        // collapse item
                        try {
                            if (ref) this.closeCard(ref)
                        } catch (error) {
                            console.log('ref close card error', error)
                        }
                        if (callbackFunction) callbackFunction()
                    }
                ).catch(
                    error => {
                        this.loading = false
                        this.handleErrors(error.response.data, formObject, objectId)
                    }
                )
            }

        },
        patch(object, ref, formObject=null, objectId=null) {
            console.log('patch object', objectId)
            this.clearErrors()
            this.loading = true
            // let data = {...object, access_code: this.$store.getters.accessCode}
            httpQuestionnaire.patch(this.db.saveLocation, object).then(
                () => {
                    this.loading = false
                    // collapse item
                    this.closeCard(ref)
                    this.$emit('refreshPatch')
                }
            ).catch(
                error => {
                    console.log(error)
                    this.loading = false
                    this.handleErrors(error.response.data, formObject, objectId)
                }
            )
        },
        deleteObject(id, callbackFunction=null) {
            this.clearErrors()
            let params = {
                id: id
            }
            httpQuestionnaire.delete(this.db.deleteLocation, {params: params}).then(
                () => {
                    // this.get()
                    if (Array.isArray(this.data)) {
                        let index = this.data.findIndex(object => object.id === id)
                        this.data.splice(index, 1)
                    } else {
                        this.data = null
                    }
                    if (callbackFunction) callbackFunction()
                    if (this.db.deleteCallbackFunction) this.db.deleteCallbackFunction()
                    this.$emit('refreshDelete')
                }
            ).catch(
                error => {
                    console.log(error)
                }
            )
        },
        clearErrors(formObject=null) {
            if (formObject) this.errorsPost[formObject] = {}
            else this.errorsPost = {}
        },
        handleErrors(errors, formObject=null, objectId=null) {
            let keys = Object.keys(errors)

            // if multiple forms with multiple errors of the same field then this will scroll to the first form
            // which may not be the desired behaviour

            // if object id then scroll to existing object field with id else new forms will not have an
            // id so try scroll to field without object id
            let id = `#${keys[0]}${objectId ? objectId : ''}`
            try {
                this.scrollTo(id) // goto field () may not be in order of listed fields
            } catch (error) {
                console.log('error scrolling to field', id, error)
            }


            if (formObject) this.errorsPost[formObject] = errors
            else this.errorsPost = errors
        },
        closeCard (ref) {
            if (ref in this.$refs) this.$refs[ref][0].show = false
            this.scrollTo(`#${ref}`)
        }
    }
}
