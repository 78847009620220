export const general = {
    data () {
        return {
            booleanYesNo: [
                {
                    label: 'Yes',
                    value: true
                },
                {
                    label: 'No',
                    value: false
                }
            ],
            booleanNoYes: [
                {
                    label: 'Yes',
                    value: false
                },
                {
                    label: 'No',
                    value: true
                }
            ]
        }
    }
}