<template>
  <QuestionnaireBase
      :title="title"
      :form="form"
      :finishFunction="null"
      :showNavigationButtons="true"
  >
    <template v-slot:default>
      <component :is="show" :key="1" :ref="show" v-model="form"/>
      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />
    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "@/views/questionnaires/loadHelpers";

import QuestionnaireBase from "../../../../components/common/questionnaires/questionnaire";
import NameBasic from "@/components/common/questionnaires/fieldsets/estatePlanning/simple/NameBasic";
import GenderDOB from "@/components/common/questionnaires/fieldsets/estatePlanning/simple/GenderDOB";
import Relationship from "@/components/common/questionnaires/fieldsets/estatePlanning/simple/Relationship";
import Address from "@/components/common/questionnaires/fieldsets/estatePlanning/simple/Address";
import Contact from "@/components/common/questionnaires/fieldsets/estatePlanning/simple/Contact";

export default {
  name: "EstatePlanningFactFindSimpleBasic",
  mixins: [loadHelpers],
  components: {
    QuestionnaireBase,
    NameBasic,
    GenderDOB,
    Contact,
    Address,
    Relationship
  },
  data() {
    return {
      name: 'EstatePlanningFactFindSimpleBasic',
      title: "Profile",
      url: 'fact_find_full',
      order: [ // imported components required here to load
        'NameBasic',
        'GenderDOB',
        'Address',
        'Relationship',
        'Contact',
      ],
    };
  },
};
</script>
