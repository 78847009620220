<template>
  <div :id="`${db.saveField}${db.objectId ? db.objectId : ''}`" class="d-flex align-items-end flex-wrap">
    <div class="field-wrapper" :class="args.inputClass">
      <label v-if="args.label" class="sub-label">{{ args.label }}</label>
      <b-textarea
          v-model="data"
          :placeholder="args.placeholder"
          :state="serverErrors.length ? false : null"
          :disabled="skipped"
          :rows="args.rows"
          :max-rows="args.maxRows"
          debounce="500"
          class="form-control standard-input"
      />
    </div>

    <div v-if="args.skippable" class="add-or flex-shrink-0">
      <label class="fancy-checkbox-container">'Skip'
        <input type="checkbox" :value="skipped" :checked="skipped ? 'checked': null" @click="skipQuestion">
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
</template>

<script>
import { skipHelpers } from "../questions/helpers/skipHelpers";
import { saveHelpers } from "@/views/questionnaires/saveHelpers";

export default {
  name: 'InputTextArea',
  mixins: [saveHelpers, skipHelpers],
  props: {
    value: {
      type: [String, Number],
      required: false
    },
    db: {
      type: Object,
      required: true
    },
    args: {
      type: Object,
      required: true
    },
    skipped: {
      type: Boolean,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      serverErrors: [],
      jsErrors: [],
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
        if (this.noSave) return
        this.$nextTick(() => {
          this.save()
        })
      },
      get() {
        return this.value
      }
    }
  }
}
</script>