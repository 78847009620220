import {httpQuestionnaire} from "@/services";
import { personalDetails } from "@/components/common/questionnaires/question/options/personalDetails";

export const relationshipStatusHelpers = {
    mixins: [personalDetails],
    methods: {
        calculateRelationshipStatus () {
            let data = {
                profileRelationshipStatus: null
            }

            // set master relationship status
            if (this.form.profileRelationshipCurrentlyYN) {
                // if currently in a relationship set master relationship status to current
                console.log('here 1')
                data.profileRelationshipStatus = this.form.profileRelationshipCurrentStatus
            }
            else if (this.form.profileRelationshipCurrentlyYN === false && this.form.profileRelationshipPreviousYN) {
                // if not currently in a relationship and was in a previous relationship, set master relationship status to previous status
                console.log('here 2')
                data.profileRelationshipStatus = this.form.profileRelationshipPreviousStatus
            }
            else if (this.form.profileRelationshipCurrentlyYN === false) {
                // if not currently in a relationship and not been married before set as single
                console.log('here 3')
                data.profileRelationshipStatus = 'single'
            } else {
                // relationship status is unknown
                console.log('here 4')
                data.profileRelationshipStatus = null
            }

            // clear hidden values
            if (this.form.profileRelationshipCurrentlyYN === false) {
                data.profileRelationshipCurrentStatus = null
                data.profileRelationshipDuration = null
            }
            if (this.form.profileRelationshipPreviousYN === false) {
                data.profileRelationshipPreviousStatus = null
                data.profileRelationshipPreviousPersonName = null
            }
            console.log(data)
            if (data.profileRelationshipStatus !== this.form.profileRelationshipStatus) {
                this.setStatus(data)
            }
        },
        setStatus (data) {
            httpQuestionnaire.patch('client_client', data).then(
                () => {
                    // set form values to same as data saved
                    let keys = Object.keys(data)
                    for (let i = 0; i < keys.length; i++) {
                        this.form[keys[i]] = data[keys[i]]
                    }
                }
            ).catch(error => {
                console.log(error)
            })
        }
    }
}
