<template>
  <div>
    <LineOne
        v-model="form.line_1"
        :no-save="noSave"
        :object-id="objectId"
        :saveLocation="saveLocation"
        :errors-post="errorsPost.line_1 || []"
    />
    <LineTwo
        v-model="form.line_2"
        :no-save="noSave"
        :object-id="objectId"
        :saveLocation="saveLocation"
        :errors-post="errorsPost.line_2 || []"
    />

    <Town
        v-model="form.post_town"
        :no-save="noSave"
        :object-id="objectId"
        :saveLocation="saveLocation"
        :errors-post="errorsPost.post_town || []" />

    <PostCode
        v-model="form.postcode"
        :no-save="noSave"
        :object-id="objectId"
        :saveLocation="saveLocation"
        :errors-post="errorsPost.postcode || []"
    />

    <Country
        v-model="form.country"
        :no-save="noSave"
        :object-id="objectId"
        :saveLocation="saveLocation"
        :errors-post="errorsPost.country || []"
    />

  </div>
</template>

<script>
import LineOne from "../../../question/questions/address/LineOne";
import LineTwo from "../../../question/questions/address/LineTwo";
import Town from "../../../question/questions/address/Town";
import PostCode from "../../../question/questions/address/PostCode";
import Country from "../../../question/questions/address/Country";

export default {
  name: 'AddressFull',
  components: {Country, PostCode, Town, LineTwo, LineOne},
  props: {
    value: {
      type: Object,
      required: true
    },
    objectId: {
      type: Number,
      required: false
    },
    saveLocation: {
      type: String,
      required: true
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>
