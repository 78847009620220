export const clientPersonOptions = {
    computed: {
        storePeople() {
            return this.$store.getters.people
        },
        partner() {
            // find partner (there will be two partners one for each family member)
            if (!this.storePeople) return null
            return this.storePeople.find(
                (person) => person.partner && person.partner !== this.$store.getters.client.id
            );
        },
        partnerRelationshipOnly () {
            return this.partnerListOptions.map(
                option => {
                    return {
                        label: option,
                        value: option.toLowerCase()
                    }
                }
            )
        },
        relationshipOptions() {
            let list = [];

            for (let i in this.partnerListOptions) {
                list.push({
                    label: this.partnerListOptions[i],
                    value: this.partnerListOptions[i].toLowerCase()
                });
            }
            for (let i in this.relationshipListOptions) {
                list.push({
                    label: this.relationshipListOptions[i],
                    value: this.relationshipListOptions[i].toLowerCase()
                });
            }
            if (!this.partner) return list;

            let relationship =
                'relationship' in this.partner
                    ? this.partner.relationship
                    : 'relationship_to_client' in this.partner
                        ? this.partner.relationship_to_client
                        : 'NO RELATIONSHIP';


            for (let i in this.relationshipListOptions) {
                list.push({
                    label: `${relationship}'s ${this.relationshipListOptions[i]}`,
                    value: `${relationship}'s ${this.relationshipListOptions[
                        i
                        ].toLowerCase()}`
                });
            }
            return list;
        }
    },
    data() {
        return {
            friendRelationshipOnly: [
                {
                    label: 'Friend',
                    value: 'friend'
                },
            ],
            dependantUntilOptions: [
                {
                    label: 'Until age 18',
                    value: 'Until age 18'
                },
                {
                    label: 'Until age 21',
                    value: 'Until age 21'
                },
                {
                    label: 'Until age 25',
                    value: 'Until age 25'
                },
                {
                    label: 'Until age 30',
                    value: 'Until age 30'
                },
                {
                    label: 'For life',
                    value: 'For life'
                }
            ],
            childRelationshipOption: [
                {
                    label: 'Son',
                    value: 'son'
                },
                {
                    label: 'Daughter',
                    value: 'daughter'
                },
            ],
            childListOptions: [
                'son',
                'daughter'
            ],
            partnerListRelationships: [
                "husband's",
                "wife's",
                "partner's",
                "civil partner's",
                "intended husband's",
                "intended wife's"
            ],
            partnerListOptions: [
                'husband',
                'wife',
                'partner',
                'civil partner',
                'intended husband',
                'intended wife'
            ],
            relationshipListOptions: [
                'son',
                'daughter',
                'brother',
                'sister',
                'niece',
                'nephew',
                'mother',
                'father',
                'cousin',
                'friend',
                'grandson',
                'granddaughter',
                'godfather',
                'goddaughter',
                'godson',
                'godmother',
                'stepfather',
                'stepmother',
                'stepson',
                'stepdaughter'
            ]
        }
    },
    methods: {
        isPartner(person) {
            if (person && person.relationship_to_client) {
                return !!this.partnerListOptions.includes(person.relationship_to_client);
            } else if (person && person.relationship) {
                return !!this.partnerListOptions.includes(person.relationship);
            }
            return false;
        },
    }
}
