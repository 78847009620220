export const statusOptions = {
    data () {
        return {
            nameChangeMethodOptions: [
                {
                    label: 'Deed Poll',
                    value: 'Deed Poll',
                },
                {
                    label: 'Marriage/Civil Partnership',
                    value: 'Marriage/Civil Partnership',
                },
                {
                    label: 'Divorce/Dissolved Civil Partnership',
                    value: 'Divorce/Dissolved Civil Partnership',
                }
            ],
            partnerListOptions: ['married', 'partnered', 'engaged', 'civil partner']
        }
    }
}
