export const blankForms = {
    data () {
        return {
            liabilityBlankForm: {
                clients: [],
                owner_other: false,
                owner_other_details: null,
                liabilityType: null,
                liabilityPayment: null,
                liabilityBalance: null,
                liabilityLender: null,
                liabilityInterestRate: null,
                liabilityInterestRateType: null,
                liabilityRepaymentType: null,
                liabilityProductEndDate: null,
                liabilityRemainingTerm: null,
                liabilityNotes: null,
                security_address: null
            },
            incomeBlankForm: {
                clients: [],
                incomeType: null,
                category: null
            },
            commitmentBlankForm: {
                clients: [],
                owner_other: false,
                owner_other_details: null,
                commitmentType: null,
                commitmentAmount: null,
                commitmentNotes: null
            },
            commitmentBlankFormMulti: {
                rent:{
                    clients: [],
                    owner_other: false,
                    owner_other_details: null,
                    commitmentType: 'Rent',
                    commitmentAmount: null,
                    commitmentNotes: null
                },
                water: {
                    clients: [],
                    owner_other: false,
                    owner_other_details: null,
                    commitmentType: 'Water',
                    commitmentAmount: null,
                    commitmentNotes: null
                },
                gasElectricity: {
                    clients: [],
                    owner_other: false,
                    owner_other_details: null,
                    commitmentType: 'Gas / Electricity',
                    commitmentAmount: null,
                    commitmentNotes: null
                },
                foodHousehold: {
                    clients: [],
                    owner_other: false,
                    owner_other_details: null,
                    commitmentType: 'Food / Household',
                    commitmentAmount: null,
                    commitmentNotes: null
                },
                internetTV: {
                    clients: [],
                    owner_other: false,
                    owner_other_details: null,
                    commitmentType: 'Internet / TV',
                    commitmentAmount: null,
                    commitmentNotes: null
                },
                councilTax: {
                    clients: [],
                    owner_other: false,
                    owner_other_details: null,
                    commitmentType: 'Council Tax',
                    commitmentAmount: null,
                    commitmentNotes: null
                },
                childcare: {
                    clients: [],
                    owner_other: false,
                    owner_other_details: null,
                    commitmentType: 'Childcare',
                    commitmentAmount: null,
                    commitmentNotes: null
                },
                travelFuel: {
                    clients: [],
                    owner_other: false,
                    owner_other_details: null,
                    commitmentType: 'Travel / Fuel',
                    commitmentAmount: null,
                    commitmentNotes: null
                },
                mobilePhone: {
                    clients: [],
                    owner_other: false,
                    owner_other_details: null,
                    commitmentType: 'Mobile Phone',
                    commitmentAmount: null,
                    commitmentNotes: null
                },
                pension: {
                    clients: [],
                    owner_other: false,
                    owner_other_details: null,
                    commitmentType: 'Pension',
                    commitmentAmount: null,
                    commitmentNotes: null
                },
                hobbies: {
                    clients: [],
                    owner_other: false,
                    owner_other_details: null,
                    commitmentType: 'Hobbies',
                    commitmentAmount: null,
                    commitmentNotes: null
                },
                regularSavings: {
                    clients: [],
                    owner_other: false,
                    owner_other_details: null,
                    commitmentType: 'Regular Savings',
                    commitmentAmount: null,
                    commitmentNotes: null
                },
            },
            assetBlankForm: {
                clients: [],
                owner_other: false,
                owner_other_details: null,
                assetType: null,
                assetValue: null,
                assetTenure: null,
                assetAcquiredDate: null,
                assetAcquiredValue: null,
                assetLocation: null,
                assetNotes: null
            },
            policyBlankForm: {
                clients: [],
                owner_other: false,
                owner_other_details: null,
                policyType: null,
                policySumAssured: null,
                policyMortgageRelated: null,
                policyProvider: null,
                policyNumber: null,
                policyCoverType: null,
                policyStartDate: null,
                policyDeferredPeriod: null,
                policyTerm: null,
                policyPremiumType: null,
                policyPremiumAmount: null,
                policyTrustStatus: null,
                policyNotes: null
            },
            addressBlankForm: {
                line_1: null,
                line_2: null,
                post_town: null,
                postcode: null,
                town: null,
                county: null,
                country: null,
                district: null
            },
            addressHistoryBlankForm: {
                address: {
                    line_1: null,
                    line_2: null,
                    post_town: null,
                    postcode: null,
                    town: null,
                    county: null,
                    country: null,
                    district: null
                }
            },
            personBlankForm: {
                clients: [],
                name_first: null,
                name_middle: null,
                name_last: null,
                dob: null,
                relationship: null,
                gender: null,
                dependant: null,
                dependant_until: null,
                address: null,
                phone: null,
                email: null
            },
            partnerBlankForm: {
                clients: [this.$store.getters.client?.id],
                name_first: null,
                name_middle: null,
                name_last: null,
                dob: null,
                relationship_to_client: null,
                gender: null,
                dependant: false,
                dependant_until: null,
                address: null,
                phone: null,
                email: null
            },
            cashGiftBlankForm: {
                name: 'Cash (Questionnaire)',
                type: 'gift',
                subType: 'cash',
                text: null,
                amount: null,
                indexed: null,
                conditionYN: null,
                condition: null,
                recipients: [],
                backupRecipients: [],
                backupRecipientsYN: null,
                shareType: 'equal',
                shareTypeBackup: 'equal',
                taxFree: null,
                stirpes: null,
                stirpesAge: null,
                preventGiftPayingTwice: true
            },
            propertyGiftBlankForm: {
                name: 'Property (Questionnaire)',
                type: 'gift',
                subType: 'property',
                text: 'the property known as <strong class="text-red">ADDRESS</strong>',
                conditionYN: null,
                condition: null,
                recipients: [],
                backupRecipients: [],
                backupRecipientsYN: null,
                shareType: 'equal',
                shareTypeBackup: 'equal',
                taxFree: null,
                stirpes: null,
                stirpesAge: null,
                hasMortgage: null,
                mortgageFree: null,
                restrictedToShareInterest: null,
                preventGiftPayingTwice: false,
                address: null,
                propertyType: null,
                userCustomText: null
                // address: { // address object applied and removed in address input
                //     line_1: null,
                //     line_2: null,
                //     post_town: null,
                //     postcode: null,
                //     country: null
                // }
            },
            jewelleryGiftBlankForm: {
                name: 'Jewellery (Questionnaire)',
                type: 'gift',
                subType: 'chattel',
                text: null,
                conditionYN: null,
                condition: null,
                recipients: [],
                backupRecipients: [],
                backupRecipientsYN: null,
                shareType: 'equal',
                shareTypeBackup: 'equal',
                taxFree: null,
                stirpes: null,
                stirpesAge: null,
                preventGiftPayingTwice: false,
                jewelleryType: null,
                userCustomText: null
            },
            accountGiftBlankFrom: {
                name: 'Account (Questionnaire)',
                type: 'gift',
                subType: 'account',
                text: null,
                conditionYN: null,
                condition: null,
                recipients: [],
                backupRecipients: [],
                backupRecipientsYN: null,
                shareType: 'equal',
                shareTypeBackup: 'equal',
                taxFree: null,
                stirpes: null,
                stirpesAge: null,
                preventGiftPayingTwice: false,
                accountType: null,
                userCustomText: null,
                accountNumber: null,
                accountProvider: null
            },
            vehicleGiftBlankForm: {
                name: 'Vehicle (Questionnaire)',
                type: 'gift',
                subType: 'chattel',
                text: null,
                conditionYN: null,
                condition: null,
                recipients: [],
                backupRecipients: [],
                backupRecipientsYN: null,
                shareType: 'equal',
                shareTypeBackup: 'equal',
                taxFree: null,
                stirpes: null,
                stirpesAge: null,
                preventGiftPayingTwice: false,
                vehicleType: null,
                userCustomText: null,
                vehicleName: null,
                vehicleMake: null,
                vehicleModel: null
            },
            otherGiftBlankForm: {
                name: 'Other (Questionnaire)',
                type: 'gift',
                subType: 'chattel',
                text: null,
                conditionYN: null,
                condition: null,
                recipients: [],
                backupRecipients: [],
                backupRecipientsYN: null,
                shareType: 'equal',
                shareTypeBackup: 'equal',
                taxFree: null,
                stirpes: null,
                stirpesAge: null,
                preventGiftPayingTwice: false
            },
            groupWillBlankForm: {
                name: null,
                recipients: [],
                recipientsType: 'equal',
                share: null,
                type: 'group',
                condition: null,
                members: []
            },
            charityWillBlankForm: {
                name: null,
                number: null,
                recipients: [],
                recipientsType: null,
                share: null,
                type: 'charity',
                condition: null
            },
            distributionBlankShareForm: {
                type: 'share',
                condition: null,
                stirpes: null,
                stirpesAge: null,
                stirpesProvision: 'either',
                share: '100%',
                shares: [],
                recipients: [],
                backupsYN: null,
                backupOption: null
            },
            conveyanceSaleBlankForm: {
                address: null,
                price: null,
                tenure: null,
                mortgage: null,
                mortgage_lender: null,
                estate_agent: null,
                notes: null,
                capacity: null,
            },
            conveyancePurchaseBlankForm: {
                address: null,
                price: null,
                tenure: null,
                mortgage: null,
                mortgage_lender: null,
                estate_agent: null,
                notes: null,
                capacity: null,
            },
            referralBlankForm: {
                name: null,
                email: null,
                phone_number: null,
                interest_type: null
            },

        }
    }
}
