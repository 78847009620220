export const textHelpers = {
    methods: {
        capitalise(value) {
            if (value) {
                let words = value.split(/[ ,]+/).filter(Boolean);
                let result = '';
                for (let i = 0; i < words.length; i++) {
                    let word = words[i].toLowerCase();
                    result += word[0].toUpperCase() + word.substr(1);
                    if (i !== words.length - 1) result += ' '
                }
                return result;
            }
            return value;
        },
        firstWord(string, plural = false) {
            let name = String(string).split(' ')[0];
            if (plural) name += "'s";
            return name;
        },
        hisHer(gender, capitalise = false) {
            if (capitalise) return gender === 'male' ? 'His' : 'Her';
            return gender === 'male' ? 'his' : 'her';
        },
        himHer(gender, capitalise = false) {
            if (capitalise) return gender === 'male' ? 'Him' : 'Her';
            return gender === 'male' ? 'him' : 'her';
        },
        heShe(gender, capitalise = false) {
            if (capitalise) return gender === 'male' ? 'He' : 'She';
            return gender === 'male' ? 'he' : 'she';
        },
        relationName(person, capitalise) {
            let string = 'my ';
            if (capitalise) string = this.capitalise(string);
            if (person.relationship) string += `${person.relationship} `;
            return (string += this.fullName(person));
        },
        highlightCapitalisedWords(string, textClass = 'text-red') {
            if (!string) return string;
            var capitalisedWords = string.match(/\b[A-Z]{2,}\b/g);
            for (let i in capitalisedWords) {
                string = string.replaceAll(
                    capitalisedWords[i],
                    `<strong class="${textClass}">${capitalisedWords[i]}</strong>`
                );
            }
            return string;
        },
        isAre(args) {
            // init
            let string = '';
            if (!('errorMessage' in args))
                args.errorMessage = 'CONFIRM RECIPIENTS WITH CLIENT';
            if (!('reverse' in args)) args.reverse = false;
            let groups = args.list.filter(
                (personGroup) => personGroup.type === 'group'
            );
            let pro = args.list.filter((item) => item.type === 'professional');
            let hasGroup = !!(groups && groups.length);

            // start
            if (args.list && args.list.length) {
                if (pro.length && args.list.length === 1) string = args.word;

                if (args.list.length === 1 && !hasGroup) {
                    string = 'is';
                } else if (args.list.length > 1 || hasGroup) {
                    string = 'are';
                }
                if (args.reverse) {
                    string[string.length - 1] === 'are'
                        ? (string = string.slice(0, -1))
                        : (string = string + 'are');
                }
                return string;
            }
            return `<strong class="text-red">${args.errorMessage}</strong>`;
        },
        hasHave(args) {
            // init
            let string = '';
            if (!('errorMessage' in args))
                args.errorMessage = 'CONFIRM RECIPIENTS WITH CLIENT';
            if (!('reverse' in args)) args.reverse = false;
            let groups = args.list.filter(
                (personGroup) => personGroup.type === 'group'
            );
            let pro = args.list.filter((item) => item.type === 'professional');
            let hasGroup = !!(groups && groups.length);

            // start
            if (args.list && args.list.length) {
                if (pro.length && args.list.length === 1) string = args.word;

                if (args.list.length === 1 && !hasGroup) {
                    string = 'has';
                } else if (args.list.length > 1 || hasGroup) {
                    string = 'have';
                }
                if (args.reverse) {
                    string[string.length - 1] === 'have'
                        ? (string = string.slice(0, -1))
                        : (string = string + 'have');
                }
                return string;
            }
            return `<strong class="text-red">${args.errorMessage}</strong>`;
        },
        oneOrMore(args) {
            // init
            let string = '';
            if (!('errorMessage' in args))
                args.errorMessage = 'CONFIRM RECIPIENTS WITH CLIENT';
            if (!('reverse' in args)) args.reverse = false;
            let groups = args.list.filter(
                (personGroup) => personGroup.type === 'group'
            );
            let pro = args.list.filter((item) => item.type === 'professional');
            let hasGroup = !!(groups && groups.length);

            // start
            if (args.list && args.list.length) {
                if (pro.length && args.list.length === 1) string = args.word;

                if (args.list.length === 1 && !hasGroup) {
                    string = args.word;
                } else if (args.list.length > 1 || hasGroup) {
                    string = args.word + 's';
                }
                if (args.reverse) {
                    string[string.length - 1] === 's'
                        ? (string = string.slice(0, -1))
                        : (string = string + 's');
                }
                return string;
            }
            return `<strong class="text-red">${args.errorMessage}</strong>`;
        },
        capitalFirst(string) {
            return string[0].toUpperCase() + string.slice(1);
        },
        listWords(args) {
            // init
            let words = 'words' in args ? args.words : [];

            let andOr = 'andOr' in args ? args.andOr : ' and ';
            let separator = 'separator' in args ? args.separator : ', ';

            let string = '';
            // start
            for (var i = 0; i < words.length; i++) {
                let word = words[i];
                // spaces, and, or
                if (i !== 0 && i !== words.length - 1)
                    string += separator;
                if (i !== 0 && i === words.length - 1) string += andOr;
                string += ` ${word}`
            }
            return string;
        }
    }
};
