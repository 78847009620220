import {httpQuestionnaire} from '@/services'
import {toast} from '@/mixins/toast'
import { clone } from "@/mixins/clone";

export const loadHelpers = {
    mixins: [toast, clone],
    mounted() {
        if (this.$route.params.code) {
            // clear tokens if logged in
            this.$store.commit('removeTokens');
            // client one time access via code
            this.$store.dispatch('setClientAuthorizationHeader', this.$route.params.code)
            this.$store.commit('accessCode', this.$route.params.code)
            // get details of the access code, used for displaying registration form
            this.fetchAccessCodeDetails()
        } else if (this.$route.params.coupon) {
            // Coupon access
            this.$store.commit('removeTokens');
            // client one time access via Coupon
            this.$store.dispatch('setClientAuthorizationHeader', this.$route.params.coupon)
            this.$store.commit('accessCode', this.$route.params.coupon)
        } else {
            // client or introducer access (logged in version)
            let params = {}
            if (this.$route.params.clientId) params.client_id = this.$route.params.clientId
            if (this.$route.params.willId) params.will_id = this.$route.params.willId
            if (this.$route.params.lpaId) params.lpa_id = this.$route.params.lpaId
            if (this.$route.params.estatePlanningSurveyId) params.survey_id = this.$route.params.estatePlanningSurveyId
            if (this.$route.params.mortgageSurveyId) params.mortgage_survey_id = this.$route.params.mortgageSurveyId
            if (this.$route.params.mortgagePropertyId) params.mortgage_property_id = this.$route.params.mortgagePropertyId
            if (this.$route.params.storageId) params.storage_id = this.$route.params.storageId
            if (this.$route.params.vaultId) params.vault_id = this.$route.params.vaultId
            if (this.$route.params.transactionId) params.transaction_id = this.$route.params.transactionId

            this.$store.dispatch('setIntroducerClientIDAuthorizationHeader', params)
        }
        this.$nextTick(() => {
            this.fetchData()
        })
    },
    beforeDestroy() {
        this.$store.dispatch('resetQuestionnaire')
    },
    computed: {
        code() {
            return this.$store.getters.accessCode
        },
        codeDetails() {
            return this.$store.getters.accessCodeDetails
        },
        couponDetails() {
            return this.$store.getters.coupon
        },
        questionsLoaded() {
            return this.$store.getters.questionsLoaded
        }
    },
    data() {
        return {
            data: null, // store original data
            form: {},
            show: null,
            load: null // load individual fieldset
        }
    },
    methods: {
        fetchData(setQuestions=true) {
            console.log('fetch data')
            // clear existing questionnaires
            this.$store.commit('fieldSetsClear')
            this.$store.commit('questionsLoaded', false)
            this.$store.commit('questionLoadError', false)
            this.$store.commit('questionLoadError401', false)
            // fetch data from server
            httpQuestionnaire.get(this.url).then(
                response => {
                    // set form data and keep in sync with v-model on questions for progress
                    this.data = response.data
                    this.form = response.data.form
                    this.$store.commit('client', response.data.client)
                    this.$store.commit('family', response.data.family)
                    this.$store.commit('people', response.data.people)
                    if (this.storeExtraGetData) this.storeExtraGetData(response.data)
                    if (setQuestions) this.setQuestions()
                }
            ).catch(
                error => {
                    console.log(error.response)
                    console.log('fetch data error')
                    // fetch access code details below handles the error message todo with access problems
                }
            )
        },
        fetchAccessCodeDetails() {
            httpQuestionnaire.get('client_access_code_detail').then(
                response => {
                    this.$store.commit('accessCodeDetails', response.data)
                }
            ).catch(
                error => {
                    console.log(error)
                    this.$store.commit('accessCodeDetails', error.response.data)
                    this.$store.commit('questionLoadError', true)
                }
            )
        },
        async setQuestions() {
            if (this.multipleSameFieldSetsConfig) {
                console.log('multiple same field')
                await this.loadFieldSetMultiple()
            }
            // load questions into dom in order to set store questions properties for progress calculations
            for (var i = this.order.length - 1; i >= 0; i--) {
                console.log(i)
                this.show = this.order[i]
                console.log('show = ', this.show, this.order)
                // wait for components to load before moving to next fieldset
                await this.timeout(70)
            }

            // load first fieldset
            console.log(this.order[0])

            this.show = this.order[0]
            console.log(this.order)

            this.$nextTick(() => {
                console.log('questions loaded')
                this.$store.commit('questionsLoaded', true)
            })
        },
        async loadFieldSetMultiple() {
            // loads multiple field sets that are the same
            // if fieldset is the same (loop)
            for (let i = 0; i < this.form[this.multipleSameFieldSetsConfig.formFieldSetData].length; i++) {
                // add component to order to show/navigation
                let name = `${this.multipleSameFieldSetsConfig.componentBaseName}${i}`
                this.order.unshift(name)
                // add component to components
                let comp = this.clone(this.multipleSameFieldSetsConfig.component)
                comp.name = name
                this.$set(this.$options.components, name, comp)
            }
            return Promise.resolve()
        },
        async timeout(ms) {
            // pass a time in milliseconds to this function
            return new Promise(resolve => setTimeout(resolve, ms));
        }
    }
}
