<template>
  <QuestionBase
      :id="db.saveField"
      :question="question"
      :errors="[...errorsPostData, ...jsErrors]"
      :valid="!!(data && data.line_1)"
  >

    <AddressSearch
        :mode-data="mode"
        :data="data"
        :formData="form"
        @modeUpdate="mode=$event"
        @formUpdate="form=$event"
        @save="save"
    />

    <!-- new form post -->
    <div
        v-if="form && mode.manual"
        class="question-box with-arrow mt-30 pb-30 clearfix"
    >
      <AddressFull
          v-model="form"
          :noSave="noSave"
          :save-location="db.saveLocation"
          :errors-post="errorsPost"
      />

      <b-button
          class="btn ml-8 mt-30"
          size="lg"
          variant="primary ripple"
          @click="saveObject(form, null, clearAll)"
      >Save
      </b-button>
    </div>

    <!-- existing object -->
    <div
        v-if="data && mode.manual"
        class="question-box with-arrow mt-30 pb-30 clearfix"
    >
      <AddressFull
          v-model="data"
          :objectId="data.id"
          :key="'address-form' + data.id"
          :save-location="db.saveLocation"
          :no-save="noSave"
      />

      <b-button
          class="btn ml-8 mt-30"
          size="lg"
          variant="primary ripple"
          @click="saveObject(null, null, clearAll)"
      >Save
      </b-button>

    </div>

    <AddressCard
        :data="data"
        :mode="mode"
        :no-save="noSave"
        @edit="editAddress"
        @delete="data = null"
    />

  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "../helpers/questionLoadHelpers";
import { clone } from "@/mixins/clone";
import { objectSaveHelpers } from "../questions/objects/objectSaveHelpers";

import QuestionBase from "../QuestionBase";
import AddressFull from "../../fieldsets/sub/address/AddressFull";
import AddressSearch from "../../fieldsets/sub/address/AddressSearch";
import AddressCard from "../../fieldsets/sub/address/AddressCard";

export default {
  name: 'InputAddress',
  mixins: [questionLoadHelpers, clone, objectSaveHelpers],
  components: {
    AddressCard,
    AddressSearch,
    AddressFull,
    QuestionBase
  },
  props: {
    value: {
      type: Object,
      required: false
    },
    questionOverride: {
      type: Object,
      required: true
    },
    dbOverride: {
      type: Object,
      required: true
    },
    jsErrorsData: {
      type: Array,
      required: true
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPostData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    question () {
      return {
        title: this.questionOverride.title,
        subTitle: this.data && !this.mode.manual ? null : !this.mode.manual ? this.questionOverride.subTitle ? this.questionOverride.subTitle + ` Enter a postcode or the first line of an address to search.` : 'Enter a postcode or the first line of an address to search.' : 'Manually enter your address below.', // TODO computed subTitle
        tip: null
      }
    },
    jsErrors: {
      set(value) {
        this.$emit('jsErrors', value)
      },
      get () {
        return this.jsErrorsData
      }
    }
  },
  data() {
    return {
      db: {
        saveLocation: this.dbOverride.saveLocation,
        saveField: this.dbOverride.saveField,
        deleteLocation: this.dbOverride.deleteLocation,
        fetchObjectsOnSave: false
      },
      args: {
        skippable: false,
        required: true,
      },
      skipped: false,
      // show forms
      mode: {
        manual: false,
        search: false
      },
      // postcode search
      query: null,
      // create new
      form: null,
      // errors
      errorsPost: {},
      serverErrors: []
    }
  },
  methods: {
    editAddress() {
      this.mode.search = false
      this.mode.manual = true
    },
    clearAll () {
      this.query = null
      this.mode.manual = false
      this.mode.search = false
    },
    setManual() {
      if (!this.data) this.setForm()
      this.mode.manual = !this.mode.manual
    },
    save(event) {
      this.saveObject(this[event], null, this.clearAll)
    }
  }
}
</script>
