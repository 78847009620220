<template>
  <div :id="`${db.saveField}${db.objectId ? db.objectId : ''}`" class="d-flex align-items-end field-wrapper field-75">
    <InputBasic
        v-model="form.day"
        :args="dayInputOptions"
        :db="db"
        :skipped="skipped"
        :no-save="true"
        :errors-post="errorsPost"
        @skipped="skipped=$event"
        @jsErrors="jsErrors=$event"
    />

    <InputBasic
        v-model=form.month
        :args="monthInputOptions"
        :db="db"
        :skipped="skipped"
        :no-save="true"
        :errors-post="errorsPost"
        @skipped="skipped=$event"
        @jsErrors="jsErrors=$event"
    />

    <InputBasic
        v-model="form.year"
        :args="yearInputOptions"
        :db="db"
        :skipped="skipped"
        :no-save="true"
        :errors-post="errorsPost"
        @skipped="skipped=$event"
        @jsErrors="jsErrors=$event"
    />
    <div v-if="args.skippable" class="add-or flex-shrink-0">
      <label class="fancy-checkbox-container">'Skip'
        <input
            type="checkbox"
            :checked="skipped ? 'checked' : null"
            :value="skipped"
            @click="skipQuestion({
            attributeToClear: 'form',
            objectToClear: {day: '', month: '', year: ''
            }})"
        >
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
</template>

<script>
import { skipHelpers } from "../questions/helpers/skipHelpers";
import { saveHelpers } from "@/views/questionnaires/saveHelpers";

import InputBasic from "./InputBasic";

export default {
  name: 'InputDateSplit',
  components: {InputBasic},
  mixins: [saveHelpers, skipHelpers],
  props: {
    value: {
      type: [String, Number],
      required: false
    },
    db: {
      type: Object,
      required: true
    },
    args: {
      type: Object,
      required: false
    },
    skipped: {
      type: Boolean,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  mounted () {
    // set initial value
    if (this.value) {
      let [year, month, day] = this.value.split('-');
      this.form = {
        year,
        month,
        day
      };
    }
  },
  data () {
    return {
      form: {
        day: null,
        month: null,
        year: null
      },
      dayInputOptions: {
        placeholder: 'DD',
        label: 'Day: (dd)',
        type: 'number',
        inputClass: 'field-100',
      },
      monthInputOptions: {
        placeholder: 'MM',
        label: 'Month: (mm)',
        type: 'number',
        inputClass: 'field-100',
      },
      yearInputOptions: {
        placeholder: 'YYYY',
        label: 'Year: (yyyy)',
        type: 'number',
        inputClass: 'field-100',
      },
      loadedData: false,
      serverErrors: [],
    }
  },
  watch: {
    form: {
      immediate: false,
      deep: true,
      handler (value) {
      if (((value.year && value.month && value.day) || (!value.year && !value.month && !value.day)) && this.loadedData) {

          if ((value.year && value.month && value.day)) {
            this.data = `${value.year}-${value.month}-${value.day}`
          } else {
            this.data = null
          }
        } else {
          this.loadedData = true
        }
      }
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
        if (this.noSave) return
        this.$nextTick(() => {
          this.save()
        })
      },
      get () {
        return this.value
      }
    }
  }
}
</script>