<template>
  <div :id="`${db.saveField}${db.objectId ? db.objectId : ''}`" class="d-flex align-items-end flex-wrap">
    <div class="field-wrapper" :class="args.inputClass">
      <label v-if="args.label" class="sub-label">{{ args.label }}</label>
      <v-select
          v-model="data"
          :options="options"
          :disabled="skipped || disabled"
          :reduce="label => label.value"
          :taggable="args.taggable"
          :multiple="multiple"
          :create-option="tag => ({ value: tag, label: tag})"
          :placeholder="args.placeholder"
          :class="{'error-border': [...errorsPost, ...serverErrors, ...jsErrors].length}"
      >
      </v-select>
    </div>
    <div v-if="args.skippable" class="add-or flex-shrink-0">
      <label class="fancy-checkbox-container" >'Skip'
        <input type="checkbox" :value="skipped" :checked="skipped ? 'checked': null" @click="skipQuestion">
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
</template>

<script>
import { skipHelpers } from "../questions/helpers/skipHelpers";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";

import vSelect from 'vue-select'

export default {
  name: 'InputSelect',
  mixins: [saveHelpers, skipHelpers],
  components: {
    vSelect
  },
  props: {
    multiple:{
      type: Boolean,
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    value: {
      type: [String, Number, Boolean, Array],
      required: false
    },
    db: {
      type: Object,
      required: true
    },
    args: {
      type: Object,
      default: () => {
        return {
          taggable: false,
          placeholder: 'Please select'
        }
      }
    },
    skipped: {
      type: Boolean,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    jsErrors: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      serverErrors: []
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
        if (this.noSave) return
        this.$nextTick(() => {
          this.save()
        })
      },
      get() {
        return this.value
      }
    }
  }
}
</script>

