<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Relationship Status</h3>

    <RelationshipCurrentlyYN
        v-model="form.profileRelationshipCurrentlyYN"
        :status-function="calculateRelationshipStatus"
    />

    <template v-if="form.profileRelationshipCurrentlyYN">
      <div class="question-box with-arrow">
        <RelationshipStatus
            v-model="form.profileRelationshipCurrentStatus"
            :status-function="calculateRelationshipStatus"
        />
        <template v-if="showRelationshipDuration">
          <Partner v-model="form.partner"/>
          <RelationshipDuration v-model="form.profileRelationshipDuration"/>
        </template>
      </div>
    </template>

    <RelationshipPreviousYN
        v-model="form.profileRelationshipPreviousYN"
        :status-function="calculateRelationshipStatus"
    />

    <template v-if="form.profileRelationshipPreviousYN">
      <div class="question-box with-arrow">
        <RelationshipPreviousStatus
            v-model="form.profileRelationshipPreviousStatus"
            :status-function="calculateRelationshipStatus"
            save-field="profileRelationshipStatus"/>
        <RelationshipPreviousPersonName
            v-model="form.profileRelationshipPreviousPersonName"
            save-field="profileRelationshipPreviousPersonName"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { fieldsetHelpers} from "@/components/common/questionnaires/fieldsets/fieldsetHelpers";
import { statusOptions } from "@/components/common/questionnaires/question/options/statusOptions";
import {
  relationshipStatusHelpers
} from "@/components/common/questionnaires/question/questions/clientProfile/relationship/relationshipStatusHelpers";

import RelationshipStatus
  from "@/components/common/questionnaires/question/questions/clientProfile/relationship/RelationshipStatus";
import RelationshipDuration
  from "@/components/common/questionnaires/question/questions/clientProfile/relationship/RelationshipDuration";

import RelationshipCurrentlyYN
  from "@/components/common/questionnaires/question/questions/clientProfile/relationship/RelationshipCurrentlyYN";
import RelationshipPreviousYN
  from "@/components/common/questionnaires/question/questions/clientProfile/relationship/RelationshipPreviousYN";
import RelationshipPreviousStatus
  from "@/components/common/questionnaires/question/questions/clientProfile/relationship/RelationshipPreviousStatus";
import RelationshipPreviousPersonName
  from "@/components/common/questionnaires/question/questions/clientProfile/relationship/RelationshipPreviousPersonName";
import Partner from "@/components/common/questionnaires/question/questions/clientProfile/status/Partner";

export default {
  name: 'Relationship',
  mixins: [fieldsetHelpers, statusOptions, relationshipStatusHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    RelationshipPreviousPersonName,
    RelationshipPreviousStatus,
    RelationshipPreviousYN,
    RelationshipCurrentlyYN,
    Partner,
    RelationshipDuration,
    RelationshipStatus,
  },
  data() {
    return {
      label: 'Relationship Status' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    showRelationshipDuration() {
      return this.partnerListOptions.includes(this.form.profileRelationshipStatus)
    }
  }
};
</script>
