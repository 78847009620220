import { textHelpers } from '@/mixins/textHelpers';

export const personHelpers = {
    mixins: [textHelpers],
    methods: {
        fullName(
            person,
            firstName = false,
            strong = false,
            firstInitial = false,
            noMiddleName = false
        ) {

            if (person) {
                if (person.name) {
                    if (firstName && person.type !== 'group') return this.firstWord(person.name);
                    if (firstInitial) return person.name[0];
                    return person.name;
                }
                let name = '';
                if (firstInitial) name = person.name_first[0] + ' ';
                else name = person.name_first + ' ';
                if (person.name_middle && !noMiddleName)
                    name += person.name_middle + ' ';
                name += person.name_last;
                if (firstName && person.type !== 'group') return this.firstWord(name);
                if (strong) return `<strong>${name}</strong>`;
                return `${name}`;
            }
            return '<span class="text-red font-weight-700">NO PERSON SET</span>';
        },
        personAddress(address, strong = false, separator = ' ') {
            if (!address) return '<strong class="text-red">NO ADDRESS</strong>';
            let string = '';
            if (address.line_1) string += address.line_1 + separator;
            if (address.line_2) string += address.line_2 + separator;
            if (address.post_town) string += address.post_town + separator;
            if (address.postcode) string += address.postcode;
            if (strong)
                return `<span class="font-weight-700 text-info">${string}</span>`;
            return string;
        },
        listPeopleAddressDob(args) {
            // init
            let string = '';
            let people = args.people;
            if (!('andOr' in args)) args.andOr = ' and ';
            if (!('relationship' in args)) args.relationship = true;
            if (!('noDob' in args)) args.noDob = false;
            if (!('space' in args)) args.space = ', ';
            if (!('address' in args)) args.address = false;
            if (!('addressSeparator' in args)) args.addressSeparator = ' ';
            if (!('addressPre' in args)) args.addressPre = 'of ';

            if (!('listItems' in args)) args.listItems = false;
            if (!('errorMessage' in args)) args.errorMessage = 'NO PEOPLE SET';
            if (!('errorBold' in args)) args.errorBold = true;
            if (!('firstName' in args)) args.firstName = false
            if (!('personBold' in args)) args.personBold = false


            // guards
            if (!people.length) {
                if (args.errorBold) {
                    return `<span class="font-weight-700 text-danger">${args.errorMessage}</span>`;
                }
                return args.errorMessage;
            }

            // start
            for (let i = 0; i < people.length; i++) {
                // spaces(, ), and/or
                if (i !== 0 && i !== people.length - 1 && !args.listItems)
                    string += args.space;
                if (i === people.length - 1 && i !== 0 && !args.listItems)
                    string += args.andOr;

                // name address dob
                let name = '';
                if (people[i].type === 'charity') {
                    // charity display name as is (no first names)
                    name += `${people[i].name}`;
                } else {
                    name += `${this.fullName(people[i], args.firstName, args.personBold)}`;

                }
                if (args.address)
                    name += `${args.addressPre}${this.personAddress(
                        people[i].address,
                        true,
                        args.addressSeparator
                    )}`;
                if (!args.noDob)
                    name += ` born ${this.formatDob({
                        date: people[i].dob,
                        strong: true
                    })}`;
                // group 'your' prefix
                if (people[i].type === 'group') name = `your ${name}`
                if (args.listItems) string += `<li>${name}</li>`;
                else string += name;
            }
            return string;
        }
    }
};
