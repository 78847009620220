<template>
  <div :id="`${db.saveField}${db.objectId ? db.objectId : ''}`" class="radio-options w-100">
    <label v-if="args.label" class="sub-label">{{ args.label }}</label>

    <label v-for="(option, index) in options"
           :key="index + db.saveField"
           :class="{ 'active' : option.value === data, 'disabled' : skipped || disabled}"
           @click="skipped || disabled ? null : data = options[index].value"
           class="radio">
      <img v-if="option.thumb && thumbs"
           :src="option.thumb"
           alt="padlock"
           class="radio-image">
      <span>{{ option.label }}</span>
    </label>

    <div v-if="args.skippable" class="add-or flex-shrink-0 mb-20">
      <label class="fancy-checkbox-container">'Skip'
        <input type="checkbox" :value="skipped" :checked="skipped ? 'checked': null" @click="skipQuestion">
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
</template>

<script>
import { skipHelpers } from "../questions/helpers/skipHelpers";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";

export default {
  name: 'InputRadio',
  mixins: [saveHelpers, skipHelpers],
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: [String, Number, Boolean],
      required: false
    },
    db: {
      type: Object,
      required: true
    },
    args: {
      type: Object,
      default: () => {
        return {}
      }
    },
    skipped: {
      type: Boolean,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    thumbs: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      serverErrors: []
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
        if (this.noSave) return
        this.$nextTick(async () => {
          await this.save()
        })
      },
      get() {
        return this.value
      }
    }
  }
}
</script>
