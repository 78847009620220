<template>
  <QuestionBase :question="question"  :errors="[...jsErrors]" :valid="!!data.length" id="partner">
    <transition name="fade">
      <div class="accordion sub-items" >

        <ObjectCard
            v-for="(partner, index) in data"
            v-model="data[index]"
            :ref="'partner'+index"
            :id="'partner'+index"
            :key="'partner' + partner.id"
            :title="`${fullName(partner)} (${ partner.relationship_to_client})`"
            :show-delete="!data[index].partner"
            :show-save="!data[index].partner"
            type="partner"
            :loading="loading"
            :badgeDisabled="true"
            @delete="deleteObject(partner.id)"
            @save="!data[index].partner ? saveObject(partner, 'partner'+index) : closeCard('partner'+index)"
        >
          <PartnerFull v-if="!data[index].partner" v-model="data[index]" :objectId="partner.id" :key="'partner-form' + partner.id"  />
          <p class="mt-24" v-else>You cannot edit your partners details from your account (your partner must edit their details from within their account).</p>
        </ObjectCard>

        <ObjectCard
            v-if="form"
            v-model="form"
            :badge-disabled="true"
            type="partner-add"
            ref="partner-add"
            title="New Partner"
            :loading="loading"
            @delete="form=null"
            @save="saveObject(form, 'partner')"
        >
          <PartnerFull v-model="form" :noSave="true" :errors-post="errorsPost"  />
        </ObjectCard>

        <b-button-group v-if="!data.length" class="w-100">
          <b-button
              @click="add"
              class="btn-question mr-1"
          >
            <i class="i-Male-2 text-25 font-weight-800 mr-2">
            </i>
            Add Partner
          </b-button>
        </b-button-group>
      </div>
    </transition>
  </QuestionBase>

</template>

<script>
import { personHelpers } from "@/mixins/personHelpers";
import { objectSaveHelpers} from "../../objects/objectSaveHelpers";
import { questionLoadHelpers } from "../../../helpers/questionLoadHelpers";
import { blankForms } from "../../../helpers/blankForms";

import QuestionBase from "../../../QuestionBase";
import ObjectCard from "../../objects/ObjectCard";
import PartnerFull from "../../../../fieldsets/sub/people/PartnerFull";

export default {
  name: 'Partner',
  components: {PartnerFull, ObjectCard, QuestionBase},
  mixins: [objectSaveHelpers, personHelpers, questionLoadHelpers, blankForms],
  props: {
    value: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      db: {
        saveLocation: 'client_person',
        saveField: 'partner',
        deleteLocation: 'client_person',
        loadLocation: 'client_person',
        syncMultipleObjectOnSave: false,
        fetchObjectsOnSave: false
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      errorsPost: {},
      jsErrors: [],
      skipped: false,
      form: null,
      errors: {}
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    question () {
      return {
        title: 'Partner',
        subTitle: this.data.length ? '' : 'Please add your partners details.',
        tip: null,
      }
    }
  },
  methods: {
    add() {
      this.form = null
      this.form = Object.assign({}, this.partnerBlankForm)
    },
  }
}
</script>
